import ApiService from "./ApiService";
import ClientAPI from "./ClientAPI";

interface Racks {
  part_id: string;
  rack_count: string;
  so_date: string;
  so_id: string;
}

export default class RackService extends ApiService {
  constructor() {
    super();
  }

  async getRackById(
    client: string,
    id: string,
    fieldnames: string,
    correls: string
  ) {
    return this.Get(`rack/${id}`, {
      Client: client,
      fieldnames,
      correls,
    })
  }

  async getRackPrice(
    client: string,
    id: string,
    part_id: string,
    count: string
  ) {
    return this.Get(`rack/${id}/price`, {
      Client: client,
      part_id,
      current_count: count.toString(),
    })
  }
}
