
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Galleria from "primevue/galleria";
import Rating from "primevue/rating";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Divider from "primevue/divider";
import Image from "primevue/image";
import ProgressSpinner from "primevue/progressspinner";
import PartsService from "@/services/PartsService";
import InventoryService from "@/services/inventory";
import { mapGetters } from "vuex";


const partService = new PartsService();
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API
);

export default defineComponent({
  name: "ProductDialog",
  components: {
    Dialog,
    Galleria,
    Rating,
    InputNumber,
    Button,
    Divider,
    Image,
    ProgressSpinner,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
    partIdFields: {
      type: Array,
      default: () => [] as any[]
    }
  },
  created() {
    if (this.productInfo.images.length === 0) {
      this.productInfo.images.push({
        itemImageSrc: require("@/assets/images/productDefaultImg.svg"),
        thumbnailImageSrc: require("@/assets/images/productDefaultImg.svg"),
        alt: "Default image",
        title: "Default image",
      });
    }
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    totalAmount() {
      return (this.productInfo.product_price * this.productQty).toFixed(2);
    },
    numImagesVisible() {
      if (this.productInfo.images.length > 3) {
        return 3;
      }
      if (this.productInfo.images.length === 1) {
        return 1;
      }
      return this.productInfo.images.length - 1;
    },
  },
  data: () => ({
    loading: false,
    showProductDetails: false,
    productQty: 0,
    productInfo: {
      part_no: "",
      manufactured_part: "",
      rating: 0,
      total_ratings: "0",
      answered_questions: 0,
      product_price: 0,
      product_characteristics: [] as any,
      about: [] as any,
      images: [] as any,
      wrap_desc: "",
      invloc: [],
    },
    responsiveOptions: [
      {
        breakpoint: "991px",
        numVisible: 4,
      },
      {
        breakpoint: "767px",
        numVisible: 3,
      },
    ],
  }),
  emits: ["closeProductDialog", "addProductToCart"],
  methods: {
    closeDialog() {
      this.showProductDetails = false;
      this.$emit("closeProductDialog");
    },
    addProductToCart() {
      this.$emit("addProductToCart", {
        ...this.selectedProduct,
        quantity: this.productQty,
      });
      this.closeDialog();
    },
    calculateStep(part: any) {
      if (part.sell_qty) {
        return parseFloat(part.sell_qty);
      } else {
        return 1;
      }
    },
    fetchData() {
      this.productInfo.part_no = this.selectedProduct.part_number;
      this.productInfo.wrap_desc = this.selectedProduct.desc;
      this.productInfo.product_price = parseFloat(this.selectedProduct.price);
      this.productQty =
        this.selectedProduct.quantity ||
        parseFloat(this.selectedProduct.sell_qty) ||
        1;
      if (this.showProductDetails === true) {
        this.productInfo.manufactured_part = "";
        this.productInfo.product_characteristics = [];
        this.loading = true;
        // partService.getPart(this.getClient, this.selectedProduct.part_number).then((res: any) => {
        //   this.productInfo.manufactured_part = res.miva_product_id ? res.miva_product_id : '';
        //   this.productInfo.product_characteristics = res.specs_items; 
        //   this.productInfo.images = res.image_desc_items?.map((item: any) => {
        //     return {
        //       image_path: item.image_path as string,
        //       image_desc: item.image_desc as string,
        //     };
        //   }) || [];
        //   this.loading = false;

        // }).catch((err) => {
        //   this.loading = false;
        //   console.log('Error ', err)
        // })
        partService
          .getPart(this.getClient, this.selectedProduct.part_number, this.customerId, "spec_desc")
          .then((res: any) => {
            this.productInfo.manufactured_part = res.miva_product_id
              ? res.miva_product_id
              : "";
            this.productInfo.product_characteristics = res.specs_items;
            const spec_desc_items = res.spec_desc || []
            for(let spec = 0; spec < res.specs_items.length; spec++) {
              if(spec_desc_items.length > spec) {
                this.productInfo.product_characteristics[spec].specs_desc = spec_desc_items[spec] 
              } else {
                this.productInfo.product_characteristics[spec].specs_desc = ""
              }
            }
            this.productInfo.images =
              res.image_desc_items === undefined ? [] : res.image_desc_items;
          })
          .catch((err) => {
            console.log("Error ", err);
          })
          .finally(() => {
            this.loading = false;
          });

        inventoryService
          .getInventory(this.selectedProduct.part_number, this.getClient, "FG", this.customerId)
          .then((res: any) => {
            this.productInfo.invloc = res ? res : [];
          })
          .catch((err) => {
            console.log("Error ", err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(value) {
        this.showProductDetails = value;
        this.fetchData();
      },
    },
  },
});
