
import { defineComponent } from "vue";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "Details",
  components: {
    Button,
    DataTable,
    Column,
    InputNumber,
  },
  props: {
    displayRacks: Boolean,
    racks: Array,
  },
  data() {
    return {
      expandedRows: [],
    };
  },
  mounted() {
    this.racks?.forEach((part: any) => {
      if(part.so_date_items && part.so_date_items.length > 0) {
        part.so_date_items.map((so: any) => {
          (so.so_racks = so.so_racks ? new Number(so.so_racks) : null),
            (so.so_date = new Date(so.so_date));
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      getStoreParts: "partsCheckout/getParts",
      getClient: "session/getClient",
      currentOrder: "pos/getCurrentOrder",
    }),
    openRacks() {
      return this.racks?.filter((rack: any) => rack.status === "O") || [];
    },
    rowClass() {
      return "p-1";
    },
    inputStyle() {
      return {
        width: "120px",
        textAlign: "right",
      };
    },
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
    }),
    addPart(part: any) {
      part.loading = true;
      if(part.part_id) {
        this.$emit("addPart", part);
      } else {
        this.addNotification({
          message: "Part ID is missing from RACK. Please contact support.",
          type: "error",
        });
        part.loading = false;
      }
    },
    getMostRecentDate(part: any) {
      const length = (part.so_date_items && part.so_date_items.length)? part.so_date_items.length : 0;
      if (length > 0) {
        const so_date_item = part.so_date_items.reduce((a: any, b: any) => {
          return new Date(a.so_date) > new Date(b.so_date) ? a : b;
        });
        return Utils.formatDate(so_date_item.so_date);
      } else {
        return "";
      }
    },
    getMostRecentCount(part: any) {
      const length = (part.so_date_items && part.so_date_items.length)? part.so_date_items.length : 0;
      if (length > 0) {
        const so_date_item = part.so_date_items.reduce((a: any, b: any) => {
          return new Date(a.so_date) > new Date(b.so_date) ? a : b;
        });
        return this.formatNumber(so_date_item.so_racks);
      } else {
        return "";
      }
    },
    formatDate(date: any) {
      return Utils.formatDate(date);
    },
    formatNumber(number: any) {
      return number.toLocaleString("en-US");
    },    
    buttonLabel(part: any) {
      return this.currentOrder?.find(
        (orderPart: any) => orderPart.part_id === part.part_id
      )
        ? "Update Cart"
        : "Add to Cart";
    },
  },
});
