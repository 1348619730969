
import { PropType, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import LookupDataTable from '@/components/Sales/LineItems/LookupDataTable.vue';

import InventoryService from '@/services/inventory';
import Lookup from '@/types/lookup';

const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API
);

export default defineComponent({
  components: {
    Button,
    Column,
    DataTable,
    Dialog,
    LookupDataTable,
    ProgressSpinner,
    TabPanel,
    TabView
  },
  props: {
    partNumber: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      default: ''
    },
  },
  inject: ["companyCode", "customerId"],
  emits: ['onClose'],
  data() {
    return {
      isLoading: false,
      part: this.partNumber,
      visible: false,
      invloc_items: [] as any[],
      lookups: [] as Lookup[],
      coCode: this.companyCode,
      custId: this.customerId,
    }
  },
  computed: {
    ...mapGetters({
      client: 'session/getClient',
      getPosInvLookups: "mrkControl/posInvLookupsItems",
    }),
    getTitle() {
      if (this.header) {
        return this.header
      }
      return `Inventory Status for "${this.part}"`
    },
    getTableInfo() {
      const info = [] as any[];
      this.invloc_items.forEach((item: any) => {
        info.push({
          invloc: item.invloc || '',
          group: item.plan_group || '-',
          on_hand: item.on_hand || '-',
          commited: item.commited || '-',
          allocated: item.allocated || '-',
          shortages: item.shortages || '-',
          available: item.available || '-',
          on_order: item.on_order || '-',
        })
      })
      return info
    },
    getLookupParameters() {
      return {
        coCode: this.coCode,
        custId: this.custId,
        parts: this.part
      }
    },
  },

  methods: {
    handleClose() {
      this.visible = false
      this.$emit('onClose')
    },

    getInventory(partId: string, client: string, correls = '') {
      this.isLoading = true
      inventoryService.getInventory(partId, client, correls)
        .then((response: any) => {
          this.invloc_items = response.invloc_items

          this.isLoading = false
        }).catch((error: any) => {
          console.log('getInventory error', error)
        })
    },
    getInvLookups() {
      return this.getPosInvLookups.map((lookup: any) => {
        return {
          key: lookup.pos_inv_lookups,
          title: lookup.inv_lookup_desc
        }
      });
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if(val) {
          this.visible = this.show
          this.part = this.partNumber
          if (this.visible) {
            this.getInventory(this.part, this.client)
          }
          this.lookups = this.getInvLookups().filter((lookup: Lookup) => lookup.key).map((lookup: Lookup) => {
            const splitLookup = lookup.key.split('*');
            return {
              ...lookup,
              lookupName: splitLookup[1],
              file: splitLookup[0],
              recordId: this.part,
            }
        })
        }
      }
    }
  }
})

